import 'particles.js'

if(window.innerWidth >= 768)
{
  particlesJS.load('particles-js-nav', '../../assets/particles-nav.json', function() {
  }); 
  particlesJS.load('particles-js-footer', '../../assets/particles-footer.json', function() {
  });
}

function particlesToggle()
{
  if(window.innerWidth >= 768 && document.getElementById("particles-js-nav").innerHTML === "")
  {
    particlesJS.load('particles-js-nav', '../../assets/particles-nav.json', function() {
    });
    particlesJS.load('particles-js-footer', '../../assets/particles-footer.json', function() {
    });
  }
  else if(window.innerWidth <= 768 && document.getElementById("particles-js-nav").innerHTML !== "")
  {
    document.getElementById("particles-js-nav").innerHTML = "";
    document.getElementById("particles-js-footer").innerHTML = "";
  }
}

var doit;
window.onresize = function(){
clearTimeout(doit);
doit = setTimeout(particlesToggle, 100);
};
