/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './canvas.js';
//import 'tinymce/skins/content/default/content.css';


//Get the button
var mybutton = document.getElementById("myBtn");
var wrapperBase = document.getElementById("wrapperBase");
// When the user scrolls down 20px from the top of the document, show the button
wrapperBase.onscroll = function() 
{
    scrollFunction()
};

function scrollFunction() 
{
    if (wrapperBase.scrollTop > 20) {
        mybutton.style.display = "block";
    } else {
        mybutton.style.display = "none";
    }
}

// When the user clicks on the button, scroll to the top of the document
mybutton.addEventListener('click', function()
{
    wrapperBase.scrollTop = 0;
})
